<script>
import { CrvTheme } from '@crv/vue-3-components'
import isNil from 'lodash/isNil'

export default {
    name: 'HorizontalBreedingValueBar',
    props: {
        value: {
            type: [ String, Number ],
            required: false,
        },
        deviationValues: {
            type: Array,
            required: false,
            default: () => [92, 96, 100, 104, 108],
            note: `The deviationValues are used to calculate the grey lines underneath the bar. 
            Normally consists of 5 values for 2Deviations, 7 values for 3Deviations,
            or 3 values for 1 deviation. Usually no header is shown in this situation.`
        },
        withSubText: {
            type: Boolean,
            required: false,
            default: false
        },
        useMultipleColors: {
            type: Boolean,
            required: false,
            default: true
        },
        barColors: {
            type: Object,
            required: false,
            default: () => ({ 
                belowMin: CrvTheme.colors['base-orange-100'],
                negative: CrvTheme.colors['base-orange-90'],
                average: CrvTheme.colors['base-gray-40'],
                positive: CrvTheme.colors['base-green-80'],
                aboveMax: CrvTheme.colors['base-green-90'],
                default: CrvTheme.colors['base-blue-70'] 
            }),
            note: `An object to replace the colors of the bar for belowMin, negative, average:, positive, aboveMax and default values respectively.
            The default color is used when the useMultipleColors prop is set to false.
            
            Note! setting this prop with the default only will make all colors the same.`
        }
    },

    mounted() {
        // StyleBindings are modified from computeds to methods.
        // This is necessary to allow for dynamic color changes based on the value of the bar.
        // This is a workaround to the limitation of computed properties not being able to take parameters.
        // Reactivity is maintained by forcing the component to re-render when needed.
        // In place when comparison bar is added.
        this.$forceUpdate()
    },

    computed: {
        minimumDeviationValue() {
            return this.deviationValues[0]
        },
        maximumDeviationValue() {
            return this.deviationValues.at(-1)
        },
        middleDeviationValue() {
            return this.deviationValues[Math.floor(this.deviationValues.length / 2)]
        },
        exteriorClass() {
            return `${this.withSubText ? 'exteriorTable' : ''}`
        },
        convertedValue(){
            return this.value?.toString().replace(',', '.') ?? this.value;
        }
    },
    methods: {
        getBarStyle() {
            const epsilon = Number.EPSILON; // Smallest possible difference, for 0,xx numbers
            
            const value = this.convertedValue;
            const middleDeviationValue = this.middleDeviationValue;
            const maximumDeviationValue = this.maximumDeviationValue;
            const minimumDeviationValue = this.minimumDeviationValue;

            if (isNaN(value) || isNil(value)) {
                return { width: '0%' };  
            }

            let barOrientation;
            if (value > middleDeviationValue + epsilon && value < maximumDeviationValue - epsilon) {
                barOrientation = 'positive';
            } else if (value >= maximumDeviationValue - epsilon) {
                barOrientation = 'aboveMax';
            } else if (value < middleDeviationValue - epsilon && value > minimumDeviationValue + epsilon) {
                barOrientation = 'negative';
            } else if (value <= minimumDeviationValue + epsilon) {
                barOrientation = 'belowMin';
            } else {
                barOrientation = 'zero';
            }

            let width, backgroundColor, transform;

            switch (barOrientation) {
                case 'positive':
                    {
                        const range = Math.max(maximumDeviationValue - middleDeviationValue, epsilon);
                        width = `${((value - middleDeviationValue) / range) * 50}%`;
                        backgroundColor = this.useMultipleColors
                            ? this.barColors.positive
                            : this.barColors.default;
                    }
                    break;
                case 'aboveMax':
                    width = `calc(50% - 10px)`; // minus the 10px margin for the triangle
                    backgroundColor = this.useMultipleColors
                        ? this.barColors.aboveMax
                        : this.barColors.default;
                    break;
                case 'negative':
                    {
                        const range = Math.max(middleDeviationValue - minimumDeviationValue, epsilon);
                        width = `${((middleDeviationValue - value) / range) * 50}%`;
                        backgroundColor = this.useMultipleColors
                            ? this.barColors.negative
                            : this.barColors.default;
                        transform = 'translateX(-100%)';
                    }
                    break;
                case 'belowMin':
                    width = `calc(50% - 10px)`; // minus the 10px margin for the triangle
                    backgroundColor = this.useMultipleColors
                        ? this.barColors.belowMin
                        : this.barColors.default;
                    transform = 'translateX(-100%)';
                    break;
                default:
                    width = '1%'; // show one pixel for zero values
                    backgroundColor = this.useMultipleColors ? this.barColors.average : this.barColors.default;
            }

            return {
                'background-color': backgroundColor,
                width,
                'margin-left': '50%', // Start from the middle
                transform,
            };
        },

        getTriangleRightStyle() {
            const color =  this.useMultipleColors ? this.barColors.aboveMax : this.barColors.default

            return {
                'border-left': `10px solid ${color}`,
                right: '1px'
            };
        },

        getTriangleLeftStyle() {
            const color = this.useMultipleColors ? this.barColors.belowMin : this.barColors.default

            return {
                'border-right': `10px solid ${color}`,
                left: '1px'
            }
        },

        getGreyBarStyle(index) {
            return {
                marginLeft: `${(index / (this.deviationValues.length - 1)) * 100}%`
            }
        }
    },
}
</script>


<template>
    <div :class="exteriorClass" class="bv-bar">
        <div class="bar">
            <span class="horizontal-breeding-bar" :style="getBarStyle(false)"></span>
            <div  v-if="convertedValue > maximumDeviationValue && convertedValue !== null && convertedValue !== 0" 
                class="triangle"
                :style="getTriangleRightStyle(false)">
            </div>
            <div v-if="convertedValue < minimumDeviationValue && convertedValue !== null && convertedValue !== 0"
                class="triangle"
                :style="getTriangleLeftStyle(false)">
            </div>
        </div>
        <div v-for="(deviation, index) in deviationValues" 
             :key="index" 
             class="grey-line" 
             :style="getGreyBarStyle(index)"
        ></div>
    </div>
</template>


<style lang="scss">
.bv-bar {
  width: 100%;
  position: relative;

    .horizontal-breeding-bar {
        z-index: 2 // positioned above the grey lines
    }

    .bar {
        display: block;
        margin-top: -10px;

        .horizontal-breeding-bar {
            height: 19px;
            display: block;
            position: absolute;
            width: 0;
            left: 0;
        }
    }


.triangle {
    top: 50%;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    z-index:2;

    @include mobile {
        display: none;
    }
}

  .grey-line {
    height: 36px;
    display: block;
    position: absolute;
    width: 1px;
    top: -8px;
    color: $base-gray-20;
    background-color: $base-gray-20;
  }
}

</style>
