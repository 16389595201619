import { BreedingValueBase, BreedingValueGroup } from "@/models/bullinfo";

export function createWithEmptyValues(): BreedingValueBase {
    const base: BreedingValueBase = {
        // hairColor: "",
        breedingValueGroupList: []
    };


    base.breedingValueGroupList.push(createGeneralGroupWithEmptyValues());
    base.breedingValueGroupList.push(createProductionGroupWithEmptyValues());
    base.breedingValueGroupList.push(createBirthGroupWithEmptyValues());
    base.breedingValueGroupList.push(createEfficiencyGroupWithEmptyValues());
    base.breedingValueGroupList.push(createExteriorAboveGroupWithEmptyValues());
    base.breedingValueGroupList.push(createExteriorBelowGroupWithEmptyValues());
    base.breedingValueGroupList.push(createHealthGroupWithEmptyValues());    
    base.breedingValueGroupList.push(createFunctionalGroupWithEmptyValues());

    return base;
}

function createGeneralGroupWithEmptyValues(): BreedingValueGroup{
    const generalGroup: BreedingValueGroup = {
        tagName: "General",
        breedingValueList: []
    }

    generalGroup.breedingValueList.push({tagName: "NVI", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "Inet", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "FoodCostSaving", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "Lifespan", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "UdderHealth", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "Fertility", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "ClawHealth", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "BirthIndex", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "Udder", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "Legwork", showOnDetailView: true});
    generalGroup.breedingValueList.push({tagName: "MeatIndex", showOnDetailView: true});

    return generalGroup;
}

function createHealthGroupWithEmptyValues(): BreedingValueGroup{
    const healthGroup: BreedingValueGroup = {
        tagName: "Health",
        breedingValueList: []
    }

    healthGroup.breedingValueList.push({showOnDetailView: true,tagName: "UdderHealth", breedingValueSubValueList: [
        {indentation: "indentation", tagName: "ClinicalMastitis"}, 
        {indentation: "indentation",  tagName: "SubclinicalMastitis"}]});
    healthGroup.breedingValueList.push({showOnDetailView: true,tagName: "Fertility", breedingValueSubValueList: [
        {indentation: "indentation",  tagName: "NonReturn"}, 
        {indentation: "indentation",  tagName: "IntervalFirstIns"},
        {indentation: "indentation",  tagName: "CalvingInterval"},
        {indentation: "indentation",  tagName: "IntervalLastIns"},
        {indentation: "indentation",  tagName: "OpenDays"},
        {indentation: "indentation",  tagName: "PregnantPercentageCow"},
        {indentation: "indentation",  tagName: "PregnantPercentagePink"},
        {indentation: "indentation",  tagName: "AgeFirstIns"}]});
    healthGroup.breedingValueList.push({showOnDetailView: true,tagName: "ClawHealth", breedingValueSubValueList: [
        {indentation: "indentation",  tagName: "SoleBleeding"}, 
        {indentation: "indentation",  tagName: "Mortellaro"},
        {indentation: "indentation",  tagName: "StinkLeg"},
        {indentation: "indentation",  tagName: "SoleUlcer"},
        {indentation: "indentation",  tagName: "Tyloma"},
        {indentation: "indentation",  tagName: "WhiteLineDefect"}]});
    healthGroup.breedingValueList.push({showOnDetailView: true,tagName: "MetabolismConditions", breedingValueSubValueList: [
        {indentation: "indentation",  tagName: "MilkDisease"}, 
        {indentation: "indentation",  tagName: "ClinicalKetosis"},
        {indentation: "indentation",  tagName: "SubclinicalKetosis"}]});
    healthGroup.breedingValueList.push({showOnDetailView: true,tagName: "ReproductionDefects", breedingValueSubValueList: [
        {indentation: "indentation",  tagName: "UncleanedUteri"}, 
        {indentation: "indentation",  tagName: "UterusInflammation"},
        {indentation: "indentation",  tagName: "CysticOvaries"},
        {indentation: "indentation",  tagName: "Anoestrus"}]});

    return healthGroup;
}

function createFunctionalGroupWithEmptyValues(): BreedingValueGroup{
    const functionalGroup: BreedingValueGroup = {
        tagName: "Functional",
        breedingValueList: []
    }

    functionalGroup.breedingValueList.push({tagName: "CellNumber", showOnDetailView: true});
    functionalGroup.breedingValueList.push({tagName: "MilkingrobotIndex", showOnDetailView: true, breedingValueSubValueList: [
            {indentation: "indentation", tagName: "MilkingrobotEfficiency"},
            {indentation: "indentation", tagName: "MilkingrobotInterval"},
            {indentation: "indentation", tagName: "MilkingrobotHabituation"}
    ]});
    functionalGroup.breedingValueList.push({tagName: "Character", showOnDetailView: true});
    functionalGroup.breedingValueList.push({tagName: "MilkingSpeed", showOnDetailView: true});
    functionalGroup.breedingValueList.push({tagName: "Persistence", showOnDetailView: true});
    functionalGroup.breedingValueList.push({tagName: "LateMaturity", showOnDetailView: true});
    functionalGroup.breedingValueList.push({tagName: "MeatIndex", showOnDetailView: true, breedingValueSubValueList: [
        {indentation: "indentation", tagName: "Conformation"},
        {indentation: "indentation", tagName: "FatAmount"},
        {indentation: "indentation", tagName: "CarcassWeight"},
        {indentation: "indentation", tagName: "ConformationCalf"},
        {indentation: "indentation", tagName: "FatAmountCalf"},
        {indentation: "indentation", tagName: "GrowthCalf"},
        {indentation: "indentation", tagName: "MeatInspectionCalf"},
        {indentation: "indentation", tagName: "ConformationBeefbull"},
        {indentation: "indentation", tagName: "FatAmountBeefbull"},
        {indentation: "indentation", tagName: "GrowthBeefbull"}]});

    return functionalGroup;
}

function createBirthGroupWithEmptyValues(): BreedingValueGroup{
    const birthGroup: BreedingValueGroup = {
        tagName: "Birth",
        breedingValueList: []
    }

    birthGroup.breedingValueList.push({tagName: "BirthIndex", breedingValueSubValueList: [
        {indentation: "indentation", tagName: "BirthEase", showOnDetailView: true},
        {indentation: "indentation", tagName: "ViabilityBirth", showOnDetailView: true},
        {indentation: "indentation", tagName: "CalvingEase", showOnDetailView: true},
        {indentation: "indentation", tagName: "ViabilityCalving", showOnDetailView: true}]});
    birthGroup.breedingValueList.push({tagName: "GestationPeriod", showOnDetailView: true});
    birthGroup.breedingValueList.push({tagName: "BirthWeight", showOnDetailView: true});
    birthGroup.breedingValueList.push({tagName: "CalfVitality", showOnDetailView: true, breedingValueSubValueList: [
        {indentation: "indentation", tagName: "CalfVitality3_14"},
        {indentation: "indentation", tagName: "CalfVitality15_180"}]});

    return birthGroup;
}

function createExteriorAboveGroupWithEmptyValues(): BreedingValueGroup{
    const exteriorGroup: BreedingValueGroup = {
        tagName: "ExteriorAbove",
        breedingValueList: []
    }

    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Frame"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Type"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Udder"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Legwork"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Muscles"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "TotalExterior"});

    return exteriorGroup;
}

function createExteriorBelowGroupWithEmptyValues(): BreedingValueGroup{
    const exteriorGroup: BreedingValueGroup = {
        tagName: "ExteriorBelow",
        breedingValueList: []
    }

    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Height"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Advance"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Content"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Openness"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "ConditionScore"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "CrossPosition"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "CrossWidth"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "LegPositionBack"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "LegPositionSide"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "ClawAngle"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "FrontLegPosition"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "LegUse"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "FrontUdderAttachment"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "FrontTeatPosition"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "TeatLength"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "UdderDepth"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "BackUdderHeight"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "Stifle"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "BackTeatPosition"});
    exteriorGroup.breedingValueList.push({showOnDetailView: true, tagName: "UdderBalance"});

    return exteriorGroup;
}

function createEfficiencyGroupWithEmptyValues(): BreedingValueGroup{
    const efficiencyGroup: BreedingValueGroup = {
        tagName: "Efficiency",
        breedingValueList: []
    }
    
    efficiencyGroup.breedingValueList.push({tagName: "Lifespan", showOnDetailView: true});
    efficiencyGroup.breedingValueList.push({tagName: "DryMatterIntake", showOnDetailView: true});
    efficiencyGroup.breedingValueList.push({tagName: "FoodCostSavingMaintenance", showOnDetailView: false});
    efficiencyGroup.breedingValueList.push({tagName: "FoodCostSaving", showOnDetailView: true});
    efficiencyGroup.breedingValueList.push({tagName: "Ureum", showOnDetailView: true});
    efficiencyGroup.breedingValueList.push({tagName: "BodyWeight", showOnDetailView: true});
    efficiencyGroup.breedingValueList.push({tagName: "LifeProductionIndex", showOnDetailView: true, breedingValueSubValueList: [
        {indentation: "indentation", tagName: "LifeProductionMilk", showOnDetailView: false},
        {indentation: "indentation", tagName: "LifeProductionFat", showOnDetailView: false},
        {indentation: "indentation", tagName: "LifeProductionLactose", showOnDetailView: false}]
    });
    efficiencyGroup.breedingValueList.push({tagName: "Methane", showOnDetailView: true});

    return efficiencyGroup;
}

function createProductionGroupWithEmptyValues(): BreedingValueGroup{
    const productionGroup: BreedingValueGroup = {
        tagName: "Production",
        breedingValueList: []
    }

    productionGroup.breedingValueList.push({tagName: "Inet", showOnDetailView: true});
    productionGroup.breedingValueList.push({tagName: "Milk", showOnDetailView: true});
    productionGroup.breedingValueList.push({tagName: "FatKg", showOnDetailView: true});
    productionGroup.breedingValueList.push({tagName: "ProteinKg", showOnDetailView: true});
    productionGroup.breedingValueList.push({tagName: "LactoseKg", showOnDetailView: true});
    productionGroup.breedingValueList.push({tagName: "FatPercentage", showOnDetailView: true});
    productionGroup.breedingValueList.push({tagName: "ProteinPercentage", showOnDetailView: true});
    productionGroup.breedingValueList.push({tagName: "LactosePercentage", showOnDetailView: true});

    return productionGroup;
}