import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from './i18n/entry';
import store from './store/store';
import DomPortal from 'vue-dom-portal';
import VueStatic from 'vue-static';
import VueObserveVisibility from 'vue-observe-visibility';
import '@crv/vue-3-components/dist/style.css'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { vuetify } from '@/plugins/vuetify'
import '@/scripts/customResizeObserver'


const app = createApp(App);

app.use(VueI18n);
app.use(store);
app.use(DomPortal);
app.use(VueStatic);
app.use(VueObserveVisibility);
app.use(vuetify)
app.use(router).mount('#app');
