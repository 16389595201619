import { Locales } from '../i18n/config/locales';

/*
    Returns a string with passed amount of decimals.
    Rounds to nearest number.
    Can be used to force a number of decimals
    e.g. (0.3,5) returns 0.30000
 */
export function displayDecimals(value: string | number, decimals: number, locale?: string): string {
    if (value === "") {return "-"}
    if (typeof(value) !== "string") {return value.toString()}
    value = value.replace(",", ".");
    if (locale !== undefined) {locale = locale.replace("_", "-")}
    const n = Number(value)
    if (isNaN(n)) {return "-"}
    if (locale === undefined) {locale = Locales.en}
    return n.toLocaleString(locale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    })
}