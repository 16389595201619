<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters, mapMutations} from "vuex";
import {BullService} from "../services/BullService";
import {formatDateMixin} from "../mixins/format-date";
import {BullInfo} from "@/models/bullinfo";
import {Device} from "@/models/enums";
import {FilterExpression} from "@/models/filterexpression";
import SireFooter from "@/components/SireFooter.vue"
import highlight from 'vue-highlight-words'
import { CrvFallbackContent, CrvSearchInput } from '@crv/vue-3-components'
import {sort} from "../scripts/sort";
import VueI18n from "../i18n/entry"
//TODO: implement i18n via services folder?
import regexescape from 'regex-escape';


import { useRouter } from 'vue-router';

export const bullService = new BullService();
const bullDetail: any = {}
const filterValues: FilterExpression[] = []

export default defineComponent({
  name: "SearchView",
  mixins: [formatDateMixin],
  components: {
    SireFooter,
    CrvSearchInput,
    CrvFallbackContent,
    highlight
  },

  props: {
    locale: {
        type: String,
        note: 'filled via router'
    },
    searchTerm: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      dialog: false,
      filteredValues: filterValues,
      windowWidth: 0,
      search: "",
      searchValue: '',
      isLoading: false,
      error: false,
      bullJson: [] as BullInfo[],
      bullDetail: bullDetail,
      sortBy: [{ key: 'dutchFlemishIndex', order: 'desc' as 'desc' | 'asc' }], // NVI
      itemsPerPage: 25,
      itemsPerPageOptions: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: 100, title: '100'},
        {value: -1, title: '∞'} 
      ],
    }
  },


  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    if (this.searchTerm && this.searchTerm !== 'undefined') {
      this.searchValue = this.searchTerm;
      this.searchBulls(this.searchValue); 
    }
  },
  computed: {
    ...mapGetters({
      getBulls: "getBulls",
      getSearchText: "getSearchText",
      getFilteredValues: "getFilteredValues"
    }),

    showTable() {
      if (this.searchValue && this.searchValue.length >= 3) {
         return true
        }
        return false
    },

    showHelperTextOrResultCount() {
      if (this.searchValue && this.searchValue.length >= 3) {
        if (this.bullJson.length === 1) {
          return VueI18n.global.t("home.foundOneText")
        }
        return `${this.bullJson.length} ${VueI18n.global.t("home.foundManyText")}`
      }
      return VueI18n.global.t("home.helper-text")
    },

    sort() {
      return (a, b) => sort(a, b, this.sortBy)
    },

    // eslint-disable-next-line @typescript-eslint/ban-types
    headerRows(): { value: string, title: string, sortable: boolean, order?: string, minWidth?: string, align?: 'start' | 'center' | 'end' | undefined, sortRaw?: Function}[] {
      return [
        {
          value: 'artificialInseminationNumber',
          title: this.$t('home.table-headers.ai'),
          sortable: true,
          minWidth: '120px',
        },
        {
          value: 'fullName',
          title: this.$t('home.table-headers.fullName'),
          sortable: true,
          minWidth: 'auto'
        },
        {
          value: 'shortName',
          title: this.$t('home.table-headers.shortName'),
          sortable: true,
          minWidth: '150px'
        },
        {
          value: 'interbullNumber',
          title: this.$t('home.table-headers.interbullNumber'),
          sortable: true,
          minWidth: '195px',
        },
        {
          value: 'dateOfBirth',
          title: this.$t('home.table-headers.dateOfBirth'),
          sortable: true,
          minWidth: '120px',
        },
        {
          value: 'referenceBase',
          title: this.$t('home.table-headers.referenceBase'),
          sortable: true,
          sortRaw: (a, b) => this.sort(a, b),
          minWidth: '140px',
        },
        {
          value: 'dutchFlemishIndex', // NVI
          title: this.$t('home.table-headers.nvi'),
          sortable: true,
          order: "desc",
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'inet',
          title: this.$t('home.table-headers.inet'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'milkKilograms',
          title: this.$t('home.table-headers.milkKilograms'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'percentageFat',
          title: this.$t('home.table-headers.percentageFat'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'percentageProtein',
          title: this.$t('home.table-headers.percentageProtein'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'foodCostSaving',
          title: this.$t('home.table-headers.foodCostSaving'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'lifeSpan',
          title: this.$t('home.table-headers.lifeSpan'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'udderHealth',
          title: this.$t('home.table-headers.udderHealth'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'fertility',
          title: this.$t('home.table-headers.fertility'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'clawHealth',
          title: this.$t('home.table-headers.clawHealth'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'birthIndex',
          title: this.$t('home.table-headers.birthIndex'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'birthEase',
          title: this.$t('home.table-headers.birthEase'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'totalExterior',
          title: this.$t('home.table-headers.totalExterior'),
          sortable: true,
          minWidth: '125px',
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'udder',
          title: this.$t('home.table-headers.udder'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'legwork',
          title: this.$t('home.table-headers.legwork'),
          sortable: true,
          align: 'end',
          sortRaw: (a, b) => this.sort(a, b),
        },
        {
          value: 'lifeNumber', // hidden from view but searchable
          title: '',
          sortable: false,
        },
      ]
    },
    sanitizedSearchValue() {
      return regexescape(this.searchValue)
    },
  },

  watch: {
    searchValue(newValue) {
      this.$router.replace({ 
          params: { 
              searchTerm: encodeURIComponent(this.searchValue), 
          } 
      });
      if (newValue && newValue.length >= 3) {
        this.searchBulls(newValue)
      } else {
        this.bullJson = []
      }
    },
    sortBy: {
      deep: true,
      immediate: false,
      handler(newSortBy, oldSortBy) {
        if (newSortBy.length === 0) {
          this.sortBy = oldSortBy
          this.sortBy[0].order = oldSortBy[0]?.order === 'asc' ? 'desc' : 'asc'
        } 
      },
    },
  },
  
  methods: {
    ...mapMutations({
      setBulls: "setBulls",
      setBullDetail: "setBullDetail",
      setSearchText: "setSearchText",
      setFilteredValues: "setFilteredValues",
      setCalcType: "setCalcType",
      setBaseColor: "setBaseColor",
      pushHistory: "pushHistory",
      jumpHistory: "jumpHistory",
    }),

    async searchBulls(searchTerm) {
      if(searchTerm && searchTerm.length >= 3) {
        this.isLoading = true;
      try {
        const tooManySearchResults = await bullService.hasTooManyResults(searchTerm)
        if (tooManySearchResults) {
          this.error = true;
          return []
        }
        this.error = false;
        const result = await bullService.getForSearchView(searchTerm, this.getAllFilteredValues())

        this.bullJson = Array.isArray(result) ? result : [];
        } catch (error) {
          console.error('API Error:', error)
        } finally {
          const refinedBullJson = this.bullJson.map(item => {
            Object.keys(item).forEach(key => {
              if (item[key] === null) {
                item[key] = '-'
              }
            })
            return item
          })

          this.setState(refinedBullJson);
          this.isLoading = false;
        }
      }
    },

    getAllFilteredValues() {
      return this.getFilteredValues ? this.getFilteredValues : []
    },

    async handleClickBodyRowNew(evt: PointerEvent, payload: any) {
      const bull = payload?.internalItem?.raw?.primaryKey || null // return null when nada?
      const result: BullInfo = await bullService.getForDetailView(bull)
      this.bullDetail = result

      this.setBullDetail(this.bullDetail);
      this.setBaseColor(this.bullDetail.referenceBase)
      if (this.bullDetail) {
        const route = {
            name: 'bull-detail',
            params: {
              locale: this.$i18n.locale,
              searchTerm: encodeURIComponent(this.searchValue), 
              interbullNumber: result.interbullNumber,
              base: result.referenceBase.replace(/[^a-zA-Z0-9]/g, ''), // remove special characters
              calculationType: result.calculationType,
              tabParam: 'General'
            },
            meta: {
               name: this.bullDetail.shortName || this.bullDetail.fullName,
               interbullNumber: this.bullDetail.interbullNumber,
            },
        }
        this.pushHistory(route)
        this.$router.push(route);
      }
    },

    /**
     * Fills the state with last queried bulls (bullJson)
     * Call this function after every query so that the state will always be up-to-date.
     */
    setState(state: BullInfo[]) {
      this.setBulls(state);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    resetSorting() {
      this.sortBy = [{ key: 'dutchFlemishIndex', order: 'desc' }]
    },
  },
  setup() {
    const router = useRouter();

    return {
      Device,
      router
    }
  },
  mounted() {
    this.$router.replace({ params: { locale: this.$i18n.locale  } });
    this.jumpHistory(0)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
});
</script>


<template>
  <div class="home">
    <h2>{{ $t("home.header") }}</h2>
    <div>
      <crv-search-input
        class="mb-4 w-25 min-width"
        v-model="searchValue"
        :placeholder="(windowWidth > Device.MOBILEMAX) ? $t('home.placeholder-text') : $t('home.placeholder-text-mobile')"
        :hint="showHelperTextOrResultCount"
        :persistent-hint="true"
        :hide-details="false"
        :error="error"
        :error-messages="error ? [$t('home.refineTheSearch')] : []"
        @cleared="resetSorting"
      />

      <v-data-table
        v-if="showTable"
        :headers="headerRows"
        :items="bullJson"
        :loading="isLoading"
        :items-per-page="itemsPerPage"
        :items-per-page-options="itemsPerPageOptions"
        item-key="name"
        v-model:sort-by="sortBy"
        fixed-header
        hover
        density="compact"
        @click:row="handleClickBodyRowNew"
      >
        <template #[`item.artificialInseminationNumber`]="{ item }">
            <highlight
                :search-words="[sanitizedSearchValue]"
                :text-to-highlight="item.artificialInseminationNumber || ''"
            />
        </template>
        <template #[`item.fullName`]="{ item }">
            <highlight
                class="text-nowrap"
                :search-words="[sanitizedSearchValue]"
                :text-to-highlight="item.fullName || ''"
            />
        </template>
        <template #[`item.shortName`]="{ item }">
            <highlight
                class="text-nowrap"
                :search-words="[sanitizedSearchValue]"
                :text-to-highlight="item.shortName || ''"
            />
        </template>
        <template #[`item.interbullNumber`]="{ item }">
            <highlight
                :search-words="[sanitizedSearchValue]"
                :text-to-highlight="item.interbullNumber || ''"
            />
        </template>
        <template #[`item.dateOfBirth`]="{ item }">
            {{ formatDate(item.dateOfBirth) }}
        </template>

        <template #[`item.percentageFat`]="{ item }">
            {{ typeof item.percentageFat === 'number' ? item.percentageFat.toFixed(2) : '-'}}
        </template>

        <template #[`item.percentageProtein`]="{ item }">
            {{ typeof item.percentageProtein === 'number' ? item.percentageProtein.toFixed(2) : '-'}}
        </template>

        <template #[`item.referenceBase`]="{ item }">
            {{ $t(`${item.referenceBase}`) }}
        </template>

        <template #[`item.lifeNumber`]="{ item }">
          <span style="display: none">{{ item.lifeNumber }}</span>
          <!-- searchable but not visible -->
        </template>

        <template #no-data>
          <crv-fallback-content
              class="overflow-hidden"
              image-name="noSearchResults"
              :text="$t('home.foundNoneText')"
          />
        </template>
      </v-data-table>
    </div>
  </div>
  <SireFooter showDisclaimer/>
</template>


<style lang="scss" scoped>
@import "../scss/home.scss";

.min-width {
  min-width: 370px;
}

.text-nowrap {
  text-wrap: nowrap;
}

@media (max-width: 959px) {

  .home {
    top: 60px;
    position: relative;
  }
}
</style>