<template>
    <div class="header_desktop d-none d-md-block">
        <div class="top-bar d-flex">
            <crv-app-locale-switcher 
                class="language-selector"
                :model-value="$i18n.locale"
                :tooltip-text="$t('localeSwitcher.tooltip')"
                :locales="locales"
                @update:model-value="handleSelectLocale"
            />
        </div>
        <div class="second-layer">
            <crvCooperationLogo @click="goBackHome" class="crv-cooperation-logo ml-12"/>
        </div>
    </div>

    <div class="header_mobile d-flex d-md-none">
        <div class="top-bar">
            <crvCooperationLogo class="crv-cooperation-logo pa-2" @click="goBackHome"/>
            <crv-app-locale-switcher 
                class="language-selector"
                :model-value="$i18n.locale"
                :tooltip-text="$t('localeSwitcher.tooltip')"
                :locales="locales"
                @update:model-value="handleSelectLocale"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { defaultLocale } from "../i18n/config";
import crvCooperationLogo from '../assets/logo/crvCooperationLogo.vue';
import { mapMutations } from "vuex";
import { CrvAppLocaleSwitcher } from '@crv/vue-3-components'
import { Locales } from '../i18n/config/locales'


export default defineComponent({
    name: "SireHeader",
    components: { 
        crvCooperationLogo,
        CrvAppLocaleSwitcher,
    },
    data: () => ({
        defaultLocale,
        locales: [Locales.en, Locales.nl],
    }),
 
    methods: {
        ...mapMutations({
            setBullDetail: "setBullDetail",
            jumpHistory: "jumpHistory",
        }),
        handleSelectLocale(locale: string) {
            this.$router.replace({ params: { locale } });
        },
        async goBackHome() {
            this.jumpHistory(0)
            await this.$router.push({ name: 'search', params: { locale: this.$i18n.locale } })
        }
    },
});
</script>

<style lang="scss" scoped>
@import "./node_modules/@crv/vue-3-components/dist/assets/variables.scss";

.header_desktop {
    .top-bar {
        background-color: $base-brand-blue;
        height: 66px;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;

        .language-selector {
            background-color: $base-brand-blue !important;
            max-width: 55px;
            margin: auto 0;
            color: $base-white !important;
        }

        .v-btn--variant-elevated {
            box-shadow: none !important;
        }
    }

    .second-layer {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        height: 90px;
        max-height: 90px;
        border-bottom: 1px solid $base-gray-40;

        .crv-cooperation-logo {
            width: 150px;
            height: 60px;
            cursor: pointer;
        }
    }
}

.header_mobile {
    .top-bar {
        position: fixed;
        top: 0;
        z-index: 2;

        background-color: $base-white;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $base-gray-40;

        .crv-cooperation-logo {
            width: 150px;
            height: 60px;
            cursor: pointer;
        }

        .language-selector {
            max-width: 55px;
            margin-top: 6px;
            margin-right: 10px;
            color: black;
        }

        .v-btn--variant-elevated {
            box-shadow: none !important;
        }
    }
}
</style>
