import debounce from 'lodash/debounce';


// debounce resize events to avoid error when mousing over language switcher
// more info here: https://github.com/vuejs/vue-cli/issues/7431
const delayMs = 20;
const observer = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends observer {
    constructor(callback: (...args) => void) {
        callback = debounce(callback, delayMs);
        super(callback);
    }
}