<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue'


export default defineComponent({
    name: 'DaughtersGeneralTable',
    props: {
        bodyRows: {
            type: Array as PropType<Array<{ id: string, cells: Array<{ id: string, value: string | number | undefined, className: string}> }>>,
            required: true
        },
        header: {
            type: String,
            required: true,
        },
        headerRows: {
            type: Array as PropType<Array<{ id: string, cells: Array<{ id: string, value: number | string, className: string }> }>>,
            required: true
        },
    },
});
</script>

<template>
    <h3 class="mt-2 pl-md-0 pl-3">
        {{ header }}
    </h3>
    <v-table density="compact">
        <thead>
            <tr>
                <th v-for="item in headerRows[0].cells" 
                    :key="item.id" 
                    :class="item.className"
                >
                    {{ item.value }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in bodyRows" 
                :key="row.id"
            >
                <td  
                    v-for="cell in row.cells" 
                    :key="cell.id"
                    :class="cell.className"
                >
                    {{ cell.value }}
                </td>
            </tr>
        </tbody>
    </v-table>
</template>