<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';
import { BreedingValue, BreedingValueSubValue } from '../models/bullinfo';
import ListBlockView from './BlockView/ListBlockView.vue';
import GeneralTableView from './BlockView/GeneralTableView.vue';
import CustomTableView from './BlockView/CustomTableView.vue';
import { TableName, HeaderValues } from '../models/enums';
import { BreedingValueGroup } from '../models/bullinfo';
import {CrvHtmlToPdf, CrvPdfPage } from '@crv/vue-3-components'
import { GeneralColumns, CustomColumns, GeneralProdColumns, GeneralColumnsWithScaleLabels } from "../models/table-headers";
import BreedingGeneralTable from '@/components/BreedingGeneralTable.vue';
import { translateDaysMixin } from '../mixins/translate-days';
import { formatDateMixin } from '../mixins/format-date';
import { formatLocale } from '@/scripts/format-locale';
import { formatBreedingValues } from "@/scripts/format-breeding-values";
import {displayDecimals} from "@/scripts/display-numbers-decimals";
import crvCooperationLogo from '../assets/logo/crvCooperationLogo.vue';
import { Locales } from '@/i18n/config/locales';

export default defineComponent({
  name: 'GeneralView',
  mixins: [translateDaysMixin, formatDateMixin],
  props: {
    referenceBase: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      generalColumns: [
        {
          id: 'general-column',
          cells: GeneralColumns
        },
      ],
      GeneralColumnsWithScaleLabels: [
        {
          id: 'general-column-with-scale-labels',
          cells: GeneralColumnsWithScaleLabels
        },
      ],
      customColumns: [
        {
          id: 'production-column',
          cells: CustomColumns
        },
      ],
      generalProdColumns: [
        {
          id: 'general-production-column',
          cells: GeneralProdColumns
        },
      ],
    };
  },
  setup() {
    const store = useStore();

    const generalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        const generalBreedingValues = store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.GENERAL);

        const filterBreedingValues = (excludedTagNames) => {
          return generalBreedingValues?.map(group => {
            return {
              ...group,
              breedingValueList: group.breedingValueList.filter(
                (value) => !excludedTagNames.includes(value.tagName)
              )
            }
          })
        }
        
        if (store.state.bullDetail.calculatedBase === 'Zwartbont' || store.state.bullDetail.calculatedBase === 'Roodbont') {
          return filterBreedingValues(['MeatIndex'])
        } else {
          return filterBreedingValues(['ClawHealth', 'FoodCostSaving'])
        }
      })

    const healthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.HEALTH);
      }); 
    const functionalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.FUNCTIONAL);
      });
    const birthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.BIRTH);
      });
    const exteriorAboveBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORABOVE);
      });

    const exteriorBelowBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORBELOW);
      });

    const efficiencyBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EFFICIENCY);
      });
    const productionBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.PRODUCTION);
      });

    return {
      generalBreedingValueGroupList,
      healthBreedingValueGroupList,
      functionalBreedingValueGroupList,
      birthBreedingValueGroupList,
      exteriorAboveBreedingValueGroupList,
      efficiencyBreedingValueGroupList,
      productionBreedingValueGroupList,
      exteriorBelowBreedingValueGroupList
    };
  },
  components: {
    ListBlockView,
    GeneralTableView,
    CustomTableView,
    CrvHtmlToPdf,
    CrvPdfPage,
    BreedingGeneralTable,
    crvCooperationLogo
  },
  computed: {
    ...mapGetters({
      bullDetail: 'getBullDetail',
    }),
    sourceProduction() {
      const source = this.productionBreedingValueGroupList[0].breedingValueList.at(0).sourceCompany
      return source ? `(${this.$t(`details.source.${source}`)})` : ''
    },
    sourceExteriorAbove() {
      const source = this.exteriorAboveBreedingValueGroupList[0].breedingValueList.at(0).sourceCompany
      return source ? `(${this.$t(`details.source.${source}`)})` : ''
    },
    sourceExteriorBelow() {
      const source = this.exteriorBelowBreedingValueGroupList[0].breedingValueList.at(0).sourceCompany
      return source ? `(${this.$t(`details.source.${source}`)})` : ''
    }
  },
  methods: {
    getDate(): any { // TODO: replace for i18n per language
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) (dd as any) = '0' + dd;
      if (mm < 10) ( mm as any) = '0' + mm;

      return dd + '/' + mm + '/' + yyyy;
    },
    getPageName(): string {
      if (this.$i18n.locale === Locales.en) {
        return 'page'
      } else {
        return 'pagina'
      }
    },
    generateFileName(): string {
      return `${this.bullDetail.shortName ? this.bullDetail.shortName : this.bullDetail.fullName ? this.bullDetail.fullName : '-'}/${this.bullDetail.artificialInseminationNumber}/${this.bullDetail.interbullNumber}`
    },


   generatePdf() {
      // button calls from BullDetailView
      (this.$refs.pdf as any).generatePdf()
    },

    mapCustomBreedingValues(list: BreedingValue[], tagName: string) {
      return list
        // Breaks the app since showOnDetailView is not always present.
        // Not sure if this still is needed
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
              alignment: 'text-left'
            },
            {
              id: 'value',
              value: value.value ?? '-',
            },
            {
              id: '%btbh',
              value: value.reliabilityPercentage ?? '-',
            },
          ],
        }));
    },
    mapCustomProductionBreedingValues(list: BreedingValue[], tagName: string) {
      return list
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
              alignment: 'text-left'
            },
            {
              id: 'value',
              value: value.tagName == "FatPercentage" || value.tagName == "ProteinPercentage" || value.tagName == "LactosePercentage" ?
          displayDecimals(value?.value == null ? "" : value.value.toString(), 2, this.$i18n.locale) : value.value
            },
            {
              id: '%btbh',
              value: value.reliabilityPercentage,
            },
          ],
        }));
    },

    returnNumberorString(value, tagname) {
            if (!value) {
                return '-'
            }

            if (tagname === 'FoodCostSavingMaintenance'){
              return this.$n(Number(displayDecimals(value, 2)), 'decimal', formatLocale(this.$i18n.locale));
            }

            if (tagname === 'DryMatterIntake' || tagname === 'Ureum') {
                return this.$n(Number(value), 'decimal', formatLocale(this.$i18n.locale))
            }
            else {
                return value
            }
        },
        
    mapGeneralBreedingValues(list: BreedingValue[], tagName: string) {
      const breedingValueArray: BreedingValue[] = [];
      for (let index = 0; index < list.length; index++) {
        const element: BreedingValue = list[index];
        breedingValueArray.push(element);

        if (element.tagName === "BirthIndex" && tagName === "Birth") {
          // eslint-disable-next-line
          const subValueList = element.breedingValueSubValueList;
          if (subValueList === undefined || subValueList.length === 0) {
            return
          }
          else {
            const birthEase = subValueList.filter(
              (subvalue: BreedingValueSubValue) =>
                subvalue.tagName === "BirthEase"
            )[0];
            breedingValueArray.push(birthEase as BreedingValue);
          }
        }
        

        if (element.tagName.includes("robot")) {
          for (let i = 0 ; element.breedingValueSubValueList!.length > i; i++){
            breedingValueArray.push(element.breedingValueSubValueList![i]);
          }
        }

        if (element.tagName.includes("Resilience")) {
          for (let j = 0 ; element.breedingValueSubValueList!.length > j; j++){
            breedingValueArray.push(element.breedingValueSubValueList![j]);
          }
        }
      }

      return breedingValueArray
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => { 
          const row = {
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
              slotName: 'text-left', // TODO: should these checks on slotNames be removed since alignment is already defined in the header?
              alignment: 'text-left'
            },
            {
              id: HeaderValues.BARSUBTEXTLEFT,
              value: value.tagValueLow ? this.$t(`details.sub-tags.${value.tagValueLow}`) : null,
              slotName: 'bar-sub-text-left',
            },
            {
              id: HeaderValues.VALUE,
              value: formatBreedingValues(value),
              slotName: 'custom-styled-column',
            },
            {
              id: HeaderValues.BARSUBTEXTRIGHT,
              value: value.tagValueLow ? this.$t(`details.sub-tags.${value.tagValueHigh}`) : null,
              slotName: 'bar-sub-text-right',
              alignment: 'text-left'
            },
            
            {
              id: HeaderValues.FW,
              value: formatBreedingValues(value)
            }
          ]
        }
        row.cells = row.cells.filter(cell => cell.value !== null) //
        return row
      }
      );
    },


    mapGeneralBreedingValuesWithTraitWeights(list: BreedingValue[], tagName: string) {
      const breedingValueArray = list.flatMap(element => {
        const values = [element];

        if (element.tagName === "BirthIndex" && tagName === "Birth") {
          const birthEase = element.breedingValueSubValueList?.find(sub => sub.tagName === "BirthEase");
          if (birthEase) values.push(birthEase as BreedingValue);
        }

        if (element.tagName.includes("robot") || element.tagName.includes("Resilience")) {
          values.push(...(element.breedingValueSubValueList ?? []));
        }

        return values;
      });

      return breedingValueArray.map(value => {
        const row = {
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
              slotName: 'text-left',
              alignment: 'text-left'
            },
            {
              id: HeaderValues.BARSUBTEXTLEFT,
              value: value.tagValueLow,
              slotName: 'bar-sub-text-left',
            },
            {
              id: HeaderValues.VALUE,
              value: formatBreedingValues(value),
              slotName: 'custom-styled-column',
            },
            {
              id: HeaderValues.BARSUBTEXTRIGHT,
              value: value.tagValueHigh,
              slotName: 'bar-sub-text-right',
              alignment: 'text-left'
            },
            {
              id: HeaderValues.FW,
              value: formatBreedingValues(value)
            }
          ]
        };
        return row;
      });
    },

  
  },
});
</script>

<template>
  <v-container fluid class="pl-0 pr-0">
    <v-row>
      <v-col cols="12" lg="6" class="pr-md-6 pr-sm-0">
        <ListBlockView :reference-base="referenceBase" class="mb-6"/>
      </v-col>
      <v-col cols="12" lg="6" class="pl-md-6 pl-sm-0">
        <GeneralTableView 
          class="mb-6"
          :body-rows="mapCustomBreedingValues(
            generalBreedingValueGroupList[0].breedingValueList,
            generalBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalProdColumns" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" class="pr-md-6 pr-sm-0">
        <h2 class="pl-md-0 pl-3">{{ $t("details.production.header") }}<span class="source ml-3">{{ sourceProduction }}</span></h2>
        <CustomTableView 
          v-if="productionBreedingValueGroupList && productionBreedingValueGroupList[0] && productionBreedingValueGroupList[0].breedingValueList.length > 0"
          class="mb-6"
          :body-rows="mapCustomProductionBreedingValues(
            productionBreedingValueGroupList[0].breedingValueList,
            productionBreedingValueGroupList[0].tagName
          )" 
          :header-rows="customColumns" 
        />
        <h2 class="pl-md-0 pl-3">{{ $t("details.efficiency.header") }}</h2>
        <BreedingGeneralTable hideHeader
          v-if="efficiencyBreedingValueGroupList && efficiencyBreedingValueGroupList[0] && efficiencyBreedingValueGroupList[0].breedingValueList.length > 0"
          class="mb-6"
          :body-rows="mapGeneralBreedingValues(efficiencyBreedingValueGroupList[0].breedingValueList, efficiencyBreedingValueGroupList[0].tagName)"
          :header-rows="generalColumns"
        />
        <h2 class="pl-md-0 pl-3">{{ $t("details.health.header") }}</h2>
        <BreedingGeneralTable
          v-if="healthBreedingValueGroupList && healthBreedingValueGroupList[0] && healthBreedingValueGroupList[0].breedingValueList.length > 0"
          class="mb-6"
          :body-rows="mapGeneralBreedingValues(healthBreedingValueGroupList[0].breedingValueList, healthBreedingValueGroupList[0].tagName)"
          :header-rows="generalColumns" 
        />
        <h2 class="pl-md-0 pl-3">{{ $t("details.functional.header") }}</h2>
        <BreedingGeneralTable
          v-if="functionalBreedingValueGroupList && functionalBreedingValueGroupList[0] && functionalBreedingValueGroupList[0].breedingValueList.length > 0"
          class="mb-6"
          :body-rows="mapGeneralBreedingValues(functionalBreedingValueGroupList[0].breedingValueList, functionalBreedingValueGroupList[0].tagName)"
          :header-rows="generalColumns" 
        />
      </v-col>
      <v-col cols="12" lg="6" class="pl-md-6 pl-sm-0">
        <h2 class="pl-md-0 pl-3">{{ $t("details.birth.header") }}</h2>
        <BreedingGeneralTable
          v-if="birthBreedingValueGroupList && birthBreedingValueGroupList[0] && birthBreedingValueGroupList[0].breedingValueList.length > 0"
          class="mb-6"
          :body-rows="mapGeneralBreedingValues(birthBreedingValueGroupList[0].breedingValueList, birthBreedingValueGroupList[0].tagName)"
          :header-rows="generalColumns" 
        />
        <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorabove.header") }}<span class="source ml-3">{{ sourceExteriorAbove }}</span></h2>
        <BreedingGeneralTable 
          v-if="exteriorAboveBreedingValueGroupList && exteriorAboveBreedingValueGroupList[0] && exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0"
          class="mb-6"
          :body-rows="mapGeneralBreedingValues(
            exteriorAboveBreedingValueGroupList[0].breedingValueList,
            exteriorAboveBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalColumns"
        />
        <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorbelow.header") }}<span class="source ml-3">{{ sourceExteriorBelow }}</span></h2>
        <BreedingGeneralTable 
          v-if="exteriorBelowBreedingValueGroupList && exteriorBelowBreedingValueGroupList[0] && exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0"
            withSubText 
            class="mb-6"
            :body-rows="mapGeneralBreedingValuesWithTraitWeights(
            exteriorBelowBreedingValueGroupList[0].breedingValueList,
            exteriorBelowBreedingValueGroupList[0].tagName,
          )
          " :header-rows="GeneralColumnsWithScaleLabels" 
        />
      </v-col>
    </v-row>
  </v-container>


  <!-- PDF VIEW -->
  <div class="hidden-from-screen">
    <crv-html-to-pdf ref="pdf" orientation="portrait" :name="generateFileName()" :pageNumberLabel="getPageName()">
      <crv-pdf-page 
        is-first-page 
        :title="bullDetail.shortName ? bullDetail.shortName : bullDetail.fullName ? bullDetail.fullName : '-'"
        :subtitle="bullDetail.fullName"
        :identifier="bullDetail.artificialInseminationNumber + ' | ' + bullDetail.lifeNumber"
        footerText=""
        phoneNumber=""
        :date-label="getDate()"
      >
      <template #logo>
        <crvCooperationLogo class="custom-logo"/>
      </template>

        <v-container fluid class="pl-0 pr-0">
          <v-row>
            <v-col cols="7" class="pr-md-6 pr-sm-0">
              <ListBlockView :reference-base="referenceBase" class="summary-print"/>
            </v-col>
            <v-col cols="5">
              <GeneralTableView 
                :body-rows="mapCustomBreedingValues(
                  generalBreedingValueGroupList[0].breedingValueList,
                  generalBreedingValueGroupList[0].tagName
                )
                " :header-rows="generalProdColumns" />
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="12" class="pr-md-6 pr-sm-0">
              <h2 class="pl-md-0 pl-3">{{ $t("details.production.header") }}<span class="source ml-3">{{ sourceProduction }}</span></h2>
              <CustomTableView 
                v-if="productionBreedingValueGroupList && productionBreedingValueGroupList[0] && productionBreedingValueGroupList[0].breedingValueList.length > 0"
                class="mb-2"
                :body-rows="mapCustomProductionBreedingValues(
                  productionBreedingValueGroupList[0].breedingValueList,
                  productionBreedingValueGroupList[0].tagName
                )" 
                :header-rows="customColumns" 
              />
              <h2 class="pl-md-0 pl-3">{{ $t("details.efficiency.header") }}</h2>
              <BreedingGeneralTable hideHeader
                v-if="efficiencyBreedingValueGroupList && efficiencyBreedingValueGroupList[0] && efficiencyBreedingValueGroupList[0].breedingValueList.length > 0"
                class="mb-2"
                :body-rows="mapGeneralBreedingValues(efficiencyBreedingValueGroupList[0].breedingValueList, efficiencyBreedingValueGroupList[0].tagName)"
                :header-rows="generalColumns"
              />
              <h2 class="pl-md-0 pl-3">{{ $t("details.health.header") }}</h2>
              <BreedingGeneralTable
                v-if="healthBreedingValueGroupList && healthBreedingValueGroupList[0] && healthBreedingValueGroupList[0].breedingValueList.length > 0"
                class="mb-6"
                :body-rows="mapGeneralBreedingValues(healthBreedingValueGroupList[0].breedingValueList, healthBreedingValueGroupList[0].tagName)"
                :header-rows="generalColumns" 
              />
            </v-col>
          </v-row>
        </v-container>
      </crv-pdf-page>

      <crv-pdf-page
          :title="bullDetail.shortName ? bullDetail.shortName : '-'"
          :subtitle="bullDetail.fullName"
          :identifier="`${bullDetail.artificialInseminationNumber} | ${bullDetail.lifeNumber}`"
          footerText=""
          phoneNumber=""
          :date-label="getDate()"
        >
          <v-container fluid class="pl-0 pr-0">
            <v-row>
              <v-col cols="12" class="pr-md-6 pr-sm-0">
                <h2 class="pl-md-0 pl-3">{{ $t("details.functional.header") }}</h2>
                  <BreedingGeneralTable
                    v-if="functionalBreedingValueGroupList && functionalBreedingValueGroupList[0] && functionalBreedingValueGroupList[0].breedingValueList.length > 0"
                    class="mb-2"
                    :body-rows="mapGeneralBreedingValues(functionalBreedingValueGroupList[0].breedingValueList, functionalBreedingValueGroupList[0].tagName)"
                    :header-rows="generalColumns" 
                  />
                  <h2 class="pl-md-0 pl-3">{{ $t("details.birth.header") }}</h2>
                  <BreedingGeneralTable
                    v-if="birthBreedingValueGroupList && birthBreedingValueGroupList[0] && birthBreedingValueGroupList[0].breedingValueList.length > 0"
                    :body-rows="mapGeneralBreedingValues(birthBreedingValueGroupList[0].breedingValueList, birthBreedingValueGroupList[0].tagName)"
                    :header-rows="generalColumns" 
                  />
                  <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorabove.header") }}<span class="source ml-3">{{ sourceExteriorAbove }}</span></h2>
                  <BreedingGeneralTable 
                    v-if="exteriorAboveBreedingValueGroupList && exteriorAboveBreedingValueGroupList[0] && exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0"
                    :body-rows="mapGeneralBreedingValues(
                      exteriorAboveBreedingValueGroupList[0].breedingValueList,
                      exteriorAboveBreedingValueGroupList[0].tagName
                    )
                    " :header-rows="generalColumns"
                  />
                  <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorbelow.header") }}<span class="source ml-3">{{ sourceExteriorBelow }}</span></h2>
                    <BreedingGeneralTable 
                      v-if="exteriorBelowBreedingValueGroupList && exteriorBelowBreedingValueGroupList[0] && exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0"
                        withSubText 
                        :body-rows="mapGeneralBreedingValuesWithTraitWeights(
                        exteriorBelowBreedingValueGroupList[0].breedingValueList,
                        exteriorBelowBreedingValueGroupList[0].tagName
                      )
                      " :header-rows="GeneralColumnsWithScaleLabels"
                    />
              </v-col>
            </v-row>
          </v-container>
        </crv-pdf-page>
    </crv-html-to-pdf>
  </div>
</template>

<style lang="scss" scoped>
//@import "@crv/vue-3-components/dist/assets/variables";
@import "./node_modules/@crv/vue-3-components/dist/assets/variables.scss";

.hidden-from-screen {
  display: none;
}

.source {
  font-size: $font-size-m;
  color: $base-gray-80;
  font-weight: $font-weight-normal;
  vertical-align: top;
}


// TODO: Most below pdf-styling should be fixed in the tool itself. Correct it and test with both sireSearch and SireMatch
.pdf-print {
  pointer-events: none;
  width: 750px; // PDF-page-width

  :deep(.pdf-page) {
    height: 1085px;
    padding-top: 12px;
  }

  .custom-logo {
    width: 200px;
    height: 80px;
  }

  :deep(.pdf-footer) {
    align-items: end;
  }

  :deep(.pdf-footer) {
    margin-bottom: 0;
  }

  :deep(.v-container) {
    padding-top: 0;
  }

  .summary-print {
    font-size: 12px;
  }

  :deep(.summary__container__listOne__columnOne){
    width: 150px;
    min-width: 150px;
  }

  h2 {
    font-size: 12px;
    font-weight: 600;
    height: 25px; // forcing less 'margins' above tables
  }

  .v-table {
    font-size: 12px;
  }

  :deep(.text-end) {
    text-align: center;
  }

  :deep(.bar){
    .triangle {
      margin-top: 6px;
      border-top: 6px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }

  :deep(.hobv-bar) {
    height: 3px;
    max-height: 3px;
  }

  :deep(span.horizontal-breeding-bar) {
    margin-top: 6px;
    background-color: tomato;
    height: 11px;
    max-height: 11px;
  }

  :deep(.list-item) {
    min-height: 20px;
  }

  :deep(.v-table--density-compact) {
    --v-table-header-height: 20px; 
    --v-table-row-height: 20px; 
  }

  :deep(.v-row + .v-row) {
    margin-top: 0;
  }

  .page {
    // max-height: 1086px;

    &.other {
      width: 100%;
      height: 300px;
    }
  }

  .spacer {
    height: 15px;
    width: 100%;
  }

  .source {
    font-size: $font-size-xxs;
    vertical-align: baseline;
  }
}
</style>