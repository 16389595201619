import { Locales } from "@/i18n/config/locales";
import VueI18n from "../i18n/entry";

export const translateDaysMixin = {
    methods: {
        translateDaysMixin(value: string): string {
            if(value === 'dagen' && VueI18n.global.locale === Locales.en) {
                return VueI18n.global.t(`details.days`)
            } else {
                return value
            }
        },
    },
  };
  