import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mt-2 pl-md-0 pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.header), 1),
    _createVNode(_component_v_table, { density: "compact" }, {
      default: _withCtx(() => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item) => {
              return (_openBlock(), _createElementBlock("th", {
                key: item.id,
                class: _normalizeClass(item.className)
              }, _toDisplayString(item.value), 3))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row.id
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: cell.id,
                  class: _normalizeClass(cell.className)
                }, _toDisplayString(cell.value), 3))
              }), 128))
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ], 64))
}