import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { class: "detail__container mb-3" }
const _hoisted_3 = { class: "detail__header d-flex align-center" }
const _hoisted_4 = {
  key: 0,
  class: "bull-name-container"
}
const _hoisted_5 = {
  key: 0,
  class: "bull-name"
}
const _hoisted_6 = { class: "bull-subname" }
const _hoisted_7 = { class: "detail__select d-flex" }
const _hoisted_8 = { class: "detail__tab-container" }
const _hoisted_9 = { style: {"display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "detail__collapse-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_breadcrumbs_divider = _resolveComponent("v-breadcrumbs-divider")!
  const _component_v_breadcrumbs_item = _resolveComponent("v-breadcrumbs-item")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_GeneralView = _resolveComponent("GeneralView")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_PedigreeView = _resolveComponent("PedigreeView")!
  const _component_BreedingValuesView = _resolveComponent("BreedingValuesView")!
  const _component_GenomicsView = _resolveComponent("GenomicsView")!
  const _component_DocumentationFormulasView = _resolveComponent("DocumentationFormulasView")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_SireFooter = _resolveComponent("SireFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_breadcrumbs, {
        class: "pl-md-0 mt-4 mb-2 overflow-x-auto",
        divider: " / ",
        items: _ctx.breadcrumbsItems
      }, {
        prepend: _withCtx(() => [
          _createVNode(_component_v_icon, {
            icon: "$home",
            size: "small",
            color: "base-gray-100",
            onClick: _ctx.handleClickHome
          }, null, 8, ["onClick"]),
          _createVNode(_component_v_breadcrumbs_divider)
        ]),
        item: _withCtx(({ item, index }) => [
          _createVNode(_component_v_breadcrumbs_item, {
            to: item.to,
            disabled: item.disabled,
            "active-color": "base-gray-80",
            color: "base-gray-80",
            class: "text-no-wrap",
            onClick: ($event: any) => (_ctx.handleClickBreadcrumbs(item, index))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1032, ["to", "disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["items"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.bullDetail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.bullDetail)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.formatEmptyToDash(_ctx.bullDetail.shortName ? _ctx.bullDetail.shortName : _ctx.bullDetail.fullName)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.bullDetail.fullName ? _ctx.bullDetail.fullName : '-') + " | " + _toDisplayString(_ctx.bullDetail.artificialInseminationNumber) + " | " + _toDisplayString(_ctx.bullDetail.interbullNumber), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_v_select, {
            items: _ctx.getHairColorOptions,
            density: "compact",
            label: _ctx.$t('details.basic-calc'),
            "bg-color": "white",
            modelValue: _ctx.getHairColorValue,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getHairColorValue) = $event)),
              _ctx.handleSelectBase
            ],
            "item-title": "optionText",
            "item-value": "id",
            "return-object": "",
            variant: "underlined",
            color: "primary"
          }, null, 8, ["items", "label", "modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_v_btn, {
            class: "pl-4 pr-2 d-md-none",
            variant: "text",
            color: "primary",
            "prepend-icon": "$printer",
            onClick: _ctx.generatePdf
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.print")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tab) = $event)),
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setGeneralView())),
                color: "base-blue-80",
                value: "General"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('details.header-overview')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPedigreeView())),
                color: "base-blue-80",
                value: "Pedigree"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('details.header-origin')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setBreedingValue())),
                color: "base-blue-80",
                value: "BreedingValues"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('details.header-breeding')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setGenomicsView())),
                color: "base-blue-80",
                value: "Genomics"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('details.header-genomics')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, {
                onClick: _ctx.setDocumentationView,
                color: "base-blue-80",
                value: "Documentation"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('header.tab-docs')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_btn, {
            class: "px-2 float-right",
            variant: "text",
            color: "primary",
            "prepend-icon": "$printer",
            onClick: _ctx.generatePdf
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.print")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createVNode(_component_v_window, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tab) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_window_item, { value: "General" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_GeneralView, {
                      key: 0,
                      ref: "generalView",
                      "reference-base": _ctx.base
                    }, null, 8, ["reference-base"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "Pedigree" }, {
              default: _withCtx(() => [
                _createVNode(_component_PedigreeView)
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "BreedingValues" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_BreedingValuesView, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "Genomics" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_GenomicsView, {
                      key: 0,
                      hasMarkerEffects: _ctx.hasMarkerEffects
                    }, null, 8, ["hasMarkerEffects"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "Documentation" }, {
              default: _withCtx(() => [
                _createVNode(_component_DocumentationFormulasView)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_v_expansion_panels, {
          variant: "accordion",
          multiple: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setGeneralView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-overview')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_GeneralView, { "reference-base": _ctx.base }, null, 8, ["reference-base"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setPedigreeView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-origin')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_PedigreeView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setBreedingValue()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-breeding')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_BreedingValuesView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setGenomicsView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-genomics')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_GenomicsView, { hasMarkerEffects: _ctx.hasMarkerEffects }, null, 8, ["hasMarkerEffects"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setDocumentationView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('header.tab-docs')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DocumentationFormulasView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_SireFooter)
  ], 64))
}