import {
    Locales
} from './locales'

import en from '../en.json'
import nl from '../nl.json'

export const messages = {
    [Locales.en]: en,
    [Locales.nl]: nl,
}

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export const defaultLocale = userLocale === Locales.en ? Locales.en : Locales.nl;