import { Locales } from "@/i18n/config/locales";
import VueI18n from "../i18n/entry";

export function formatNumber(value: string): string {
    // TODO: when we are gonna use the locale, determine locale through parameter or environment
  
    if (value === null || value === undefined || value === ""){
      return "-";
    }
  
    if (VueI18n.global.locale === Locales.nl) {
      const inputString = String(value); // value appears not always to be a string, so convert to make this work.
          if (inputString.includes('.')) {
            return inputString.replace(/\./g, ',')
          } else {
            return inputString
          }
    } else if (value === 'dagen' && VueI18n.global.locale === Locales.en) {
      return VueI18n.global.t(`details.days`)
    } else {
      return value ? value : ' - '
    }
  }