import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header_desktop d-none d-md-block" }
const _hoisted_2 = { class: "top-bar d-flex" }
const _hoisted_3 = { class: "second-layer" }
const _hoisted_4 = { class: "header_mobile d-flex d-md-none" }
const _hoisted_5 = { class: "top-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crv_app_locale_switcher = _resolveComponent("crv-app-locale-switcher")!
  const _component_crvCooperationLogo = _resolveComponent("crvCooperationLogo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_crv_app_locale_switcher, {
          class: "language-selector",
          "model-value": _ctx.$i18n.locale,
          "tooltip-text": _ctx.$t('localeSwitcher.tooltip'),
          locales: _ctx.locales,
          "onUpdate:modelValue": _ctx.handleSelectLocale
        }, null, 8, ["model-value", "tooltip-text", "locales", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_crvCooperationLogo, {
          onClick: _ctx.goBackHome,
          class: "crv-cooperation-logo ml-12"
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_crvCooperationLogo, {
          class: "crv-cooperation-logo pa-2",
          onClick: _ctx.goBackHome
        }, null, 8, ["onClick"]),
        _createVNode(_component_crv_app_locale_switcher, {
          class: "language-selector",
          "model-value": _ctx.$i18n.locale,
          "tooltip-text": _ctx.$t('localeSwitcher.tooltip'),
          locales: _ctx.locales,
          "onUpdate:modelValue": _ctx.handleSelectLocale
        }, null, 8, ["model-value", "tooltip-text", "locales", "onUpdate:modelValue"])
      ])
    ])
  ], 64))
}