import { BullInfo } from '@/models/bullinfo';
import { FilterExpression } from '@/models/filterexpression';
import { createStore } from 'vuex';
import { type RouteRecordNormalized } from 'vue-router';
export interface BullState {
  searchText: string;
  bulls: Array<BullInfo>;
  bullDetail: BullInfo;
  filteredValues: FilterExpression[]
  pedigreeState: number;
  pedigreeBull: BullInfo;
  baseColor: string;
  calcType: string;
  history: Array<RouteRecordNormalized>;
}

export default createStore({
  state: {
    searchText: '',
    bulls: [],
    bullDetail: null,
    filteredValues: [],
    pedigreeState: 1,
    pedigreeBull: null,
    baseColor: 'Zwartbont',
    calcType: 'GENOMIC_BREEDING_VALUE',
    history: [],
  } as unknown as BullState,
  getters: {
    getSearchText(state: BullState) {
      return state.searchText;
    },
    getBulls: (state: BullState) => state.bulls,

    getBullDetail: (state: BullState) => state.bullDetail,

    getFilteredValues(state: BullState) {
      return state.filteredValues;
    },
    getPedigreeState(state: BullState) {
      return state.pedigreeState;
    },
    getPedigreeBull(state: BullState) {
      return state.pedigreeBull;
    },
    getBaseColor(state: BullState) {
      return state.baseColor;
    },
    getCalcType(state: BullState) {
      return state.calcType;
    },
    getHistory(state: BullState) {
      return state.history;
    }
  },
  mutations: {
    setSearchText(state: BullState, payload) {
      state.searchText = payload;
    },
    setBulls(state: BullState, payload) {
      state.bulls = payload;
    },
    setBullDetail(state: BullState, payload) {
      state.bullDetail = payload;
    },
    setBreedingValueBase(state: BullState, payload) {
      state.bullDetail.breedingValueBase = payload;
    },
    setFilteredValues(state: BullState, payload) {
      state.filteredValues = payload;
    },
    setPedigreeState(state: BullState, payload) {
      state.pedigreeState = payload;
    },
    setPedigreeBull(state: BullState, payload) {
      state.pedigreeBull = payload;
    },
    setBaseColor(state: BullState, payload) {
      state.baseColor = payload;
    },
    setCalcType(state: BullState, payload) {
      state.calcType = payload;
    },
    pushHistory(state: BullState, payload) {
      if (state.history.length === 0 || state.history.at(-1)?.meta.interbullNumber !== payload.meta.interbullNumber) {
          state.history.push(payload);
      }
    },
    popHistory(state: BullState) {
      state.history.pop();
    },
    jumpHistory(state: BullState, index) {
      state.history.splice(index, Infinity);
    },
  },
});
